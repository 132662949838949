<template>
  <main class="main options-open-page">
    <div class="container" v-if="option">
      <BreadcrumbsComponent :title="pageTitle" v-if="pageTitle" />
      <section class="prg">
        <div class="prg__inner-fix">
          <div>
            <div class="prg__desc" v-if="option.description">
              <EditorJSComponent :text="JSON.parse(option.description)" />
            </div>
            <h2 class="prg__tabs__title" v-if="programs.length">Совместимо с программами</h2>
            <div class="prg__tabs__buttons" v-if="programs.length">
              <router-link
                :to="{ name: 'program', params: { url: program.link } }"
                class="btn"
                type="button"
                v-for="(program, index) in programs"
                :key="index"
                >{{ program.button_title || program.title }}
              </router-link>
            </div>
            <h2 class="prg__tabs__title" v-if="vehicles.length > 0">Дополнительные условия</h2>
            <div class="prg__tabs__buttons" v-if="vehicles.length > 0">
              <button
                v-for="(vehicle, index) in vehicles"
                :key="index"
                class="btn-dark"
                :class="{ active: activeVehicle === vehicle.id }"
                @click="activeVehicle = vehicle.id"
                type="button"
              >
                {{ vehicle.button_title || vehicle.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="prg__conditions" v-if="conditions && conditions.length">
          <ul class="prg__conditions__list">
            <li v-for="(condition, index) in conditions" :key="index">
              <div class="prg__conditions__content">
                <span class="prg__conditions__date" v-if="condition.from">{{ condition.from }}</span>
                <span class="prg__conditions__number" v-if="condition.value">{{ condition.value }}</span>
                <span class="prg__conditions__modifier" v-if="condition.mod">{{ condition.mod }}</span>
              </div>
              <h3 class="prg__conditions__name">{{ condition.title }}</h3>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <section class="container _mb">
      <div
        class="about"
        v-if="
          option && option.text && JSON.parse(option.text).blocks && JSON.parse(option.text).blocks.length
        "
      >
        <EditorJSComponent :text="JSON.parse(option.text)" />
      </div>
      <div class="prg__advantages" v-if="advantages.length">
        <h2 class="prg__tabs__title">Преимущества «Лизинг-Трейд»</h2>
        <ul class="prg__advantages-list">
          <li class="prg__advantages-item" v-for="(advantage, index) in advantages" :key="index">
            <i class="icon" v-html="advantage.icon"></i>
            <div class="prg__advantages-content">
              <div class="prg__advantages-title">{{ advantage.title }}</div>
              <div class="prg__advantages-text">
                <EditorJSComponent :text="getAdvantagesDescription(advantage)" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="prg__request">
      <div class="container">
        <LeasingRequestModal />
      </div>
    </section>
    <section class="container _mb" v-if="option && option.conditions">
      <div class="inv__tabs">
        <ul class="inv__tabs__list">
          <li class="active">
            <InfoIcon />
            <span>Условия</span>
          </li>
        </ul>
        <div class="inv__tabs__content">
          <EditorJSComponent :text="JSON.parse(option.conditions)" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import LeasingRequestModal from "components/modals/LeasingRequest.vue";
import InfoIcon from "components/svg/Info.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import { cityIn } from "lvovich";

export default {
  name: "OptionsPage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_OPTION_PAGE", route.params.url);
  },
  data() {
    return {
      activeVehicle: null,
    };
  },
  computed: {
    conditionsRaw() {
      if (
        this.$store.state.option_page &&
        this.$store.state.option_page.option &&
        this.$store.state.option_page.option.conditions_array &&
        this.$store.state.option_page.option.conditions_array.length
      ) {
        return this.$store.state.option_page.option.conditions_array;
      } else {
        return undefined;
      }
    },
    conditions() {
      if (
        this.$store.state.option_page &&
        this.$store.state.option_page.option &&
        this.$store.state.option_page.option.conditions_array.length
      ) {
        if (this.activeVehicle) {
          return this.$store.state.option_page.option.conditions_array.filter(
            (item) => item.vehicle_id === this.activeVehicle
          );
        } else {
          return this.$store.state.option_page.option.conditions_array;
        }
      } else {
        return undefined;
      }
    },
    programs() {
      if (this.option && this.option.programs) {
        return this.option.programs.filter((item, index, array) => {
          return index === array.findIndex((t) => t.id === item.id);
        });
      } else {
        return [];
      }
    },
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.option_page && this.$store.state.option_page.option) {
        page = this.$store.state.option_page.option;
      }
      return (page.title || page.meta_title).replace(/{{ city }}/g, `в ${city}`);
    },
    option() {
      if (this.$store.state.option_page && this.$store.state.option_page.option) {
        return this.$store.state.option_page.option;
      } else {
        return undefined;
      }
    },
    advantages() {
      if (this.option && this.option.advantages) {
        return this.option.advantages;
      } else {
        return [];
      }
    },
    vehicles() {
      if (this.$store.state.option_page && this.$store.state.option_page.vehicles) {
        if (this.conditionsRaw) {
          return this.$store.state.option_page.vehicles.filter(
            (v) => this.conditionsRaw.filter((c) => c.vehicle_id === v.id).length
          );
        }
      }
      return [];
    },
  },
  mounted() {
    if (this.vehicles && this.vehicles.length) {
      this.activeVehicle = this.vehicles[0].id;
    }
  },
  methods: {
    getAdvantagesDescription(adv) {
      if (adv.description) {
        let haveError = false;
        try {
          JSON.parse(adv.description);
        } catch (e) {
          haveError = true;
        }
        if (!haveError) {
          return JSON.parse(adv.description);
        } else {
          return {
            blocks: [
              {
                type: "paragraph",
                data: { text: adv.description },
              },
            ],
          };
        }
      }
      return {
        blocks: [],
      };
    },
  },
  components: {
    EditorJSComponent,
    InfoIcon,
    LeasingRequestModal,
    BreadcrumbsComponent,
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.option_page && this.$store.state.option_page.option) {
      data = this.$store.state.option_page.option;
    }
    const title = (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`);
    let metaFullPath = this.$route.fullPath;
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: title,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/prg.styl"
@import "~@/styles/parts/inv.styl"
@import "~@/styles/mixins/margin.styl"
.options-open-page {
  .about {
    border: 1px solid main_gray;
    padding 30px
  }
}
</style>
